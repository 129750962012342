<template>
	<span>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button :disabled="disabled" type="primary">选择影院</a-button>
<!--					<span> 已选{{selectedRowKeys.length}}影院</span>-->
				</slot>
			</span>

			<a-modal v-model:visible="showSnackModal" title="选择影院" destroyOnClose width="880px" @cancel="onCancel" @ok="onOK">
				<a-spin :spinning="loading">
					<a-form ref="form" name="form" :model="formState" @finish="onSearch">
						<a-row>
							<a-col :span="18">
								<a-row>
									<a-form-item name="cinemaInfo" label="影院名称" style="margin-right: 30px;">
										<a-input v-model:value="formState.cinemaInfo" placeholder="请输入影院名称"></a-input>
									</a-form-item>
								</a-row>
							</a-col>

							<a-col :span="6" style="text-align: right;">
								<a-button type="primary" html-type="submit">搜索</a-button>
								<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
							</a-col>
						</a-row>

						<a-row>
							<a-col :span="18"></a-col>

						</a-row>
					</a-form>
					<div style="max-height: 590px;overflow-y: auto;">
						<a-table rowKey="id" :dataSource="dataList" :pagination="pagination"
                     :columns="columns"
                     :rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, onChange: onChange }"
                     bordered size="middle"
            >
						</a-table>
					</div>
				</a-spin>
			</a-modal>
		</a-spin>
	</span>
</template>

<script>
import { getCinemaList } from '@/service/modules/cinema.js';
export default {
  model: {
    prop: 'value'
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    discountIds :{
      type: Array,
      default: () => {
        return []
      }
    },
    policyIds :{
      type: String,
      default: ''
    },
    selectItem: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    showType: {
      type: String,
      default: ''
    },
    searchMap: { // 外部传入搜索数据
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSee: {
          type: Boolean,
          default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    cinemaIds:{
      type: Object,
      default: () => ({})
    },
    disCinemaIds:{
      type: Array,
      default: () => {
        return []
      }
    },
    polCinedmaIds:{
      type: Array,
      default: () => {
        return []
      }
    },
    cinemaListIds:{
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      loading: false,
      showSnackModal: false,
      classifyList: [],
      formState: {
        cinemaInfo:''
      },
      searchData: {},
      selectedRowKeys: [],
      selectedListData:[],
      dataList: [],
      columns: [{
        title: '影院编码',
        dataIndex: 'code'
      },
        {
          title: '影院名称',
          dataIndex: 'name'
        },
        // {
        // 	title: '语言',
        // 	dataIndex: 'language',
        // 	width: 80,
        // 	align: 'center'
        // },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        cinemaTotal:0,
        showTotal: () => {
          return "共 " + this.pagination.cinemaTotal + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  watch: {
    selectItem: {
      handler(newVal) {
        this.selectedListData = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true
    },
    value: {
      handler(newVal) {
        this.selectedRowKeys = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true
    }
  },
  created(){
  },
  methods: {
    onShowModal() {
      if (this.disabled) return;
      this.getData();
      this.showSnackModal = true;
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.searchData.cinemaInfo = this.searchData.cinemaInfo !=''?this.searchData.cinemaInfo:undefined
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.formState.cinemaInfo = ''
      this.onSearch();
    },
    async getData() {
      // this.searchData.type = 1;
      this.loading = true;
      try {
        let ret = await getCinemaList({
          page: 1,
          pageSize: 999999,
          ...this.searchData
        })
        this.loading = false;
        if (ret.code === 200) {
          this.dataList = ret.data.list;
          this.pagination.total = ret.data.totalCount;
          if (this.isSee || this.isEdit){
            this.dataList = this.filterArray(this.value,ret.data.list)
          }
          if (this.discountIds && this.showType == 'discount'){
            this.dataList = this.filterArray(this.discountIds,ret.data.list)
            this.dataList = this.dataList.filter(cinema =>
              this.disCinemaIds.includes(cinema.id.toString())
            );
          }
          if (this.polCinedmaIds && this.showType == 'policy'){
            this.dataList = this.filterArray(this.policyIds,ret.data.list,'policy')
            this.dataList = this.dataList.filter(cinema =>
              this.polCinedmaIds.includes(cinema.id.toString())
            );
          }
          this.pagination.cinemaTotal = this.dataList.length
        }
      } catch(e) {
        //console.log(e);
        this.loading = false;
      }
    },
    filterArray(obj, arr ,type){
      if (type == 'policy') {
        return arr.filter(item => item.id != obj);
      }else {
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => !values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      }

    },
    hasArray(obj, arr){
      const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
      return arr.filter(item => values.includes(item.id)); // 过滤数组，返回包含对象值的元素
    },
    onSelectChange(record, selected) {

      if (this.type === 'checkbox') {
        if (selected) {
          this.selectedListData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          this.selectedListData = this.selectedListData.filter(item => {
            return item.id !== record.id;
          });
        }
      } else {
        if (selected) {
          this.selectedListData = [JSON.parse(JSON.stringify(record))];
          this.selectedRowKeys = [record.id];
        } else {
          this.selectedListData = [JSON.parse(JSON.stringify(record))];
          this.selectedRowKeys = [];
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.dataList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            this.selectedListData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.dataList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    onChange(key) {

    },
    onCancel() {
      this.showSnackModal = false;
      this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
      this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
    },
    onOK() {
      this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
      this.$emit('change', JSON.parse(JSON.stringify(this.selectedListData)));
      this.showSnackModal = false;
    }
  }
}
</script>

<style>
</style>
